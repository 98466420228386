

.app__about{
    flex:1;
    width:100%;
    flex-direction:column;
    margin-top:6rem;
    
    //added
    //display:flex;
    //justify-content: flex-end;
    //align-items:center;

    @media screen and(max-width:1500px) {
        margin-top:2rem;
        
    }
}

.app__about-text{
    margin:auto;
    width:fit-content;

    h2{
        text-align: left;
        //@debug: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.app__profiles{
    display:flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.app__profile-item{
    width:300px;
    display: flex;
    justify-content:flex-start;
    align-items:flex-start;
    flex-direction:column;
    margin:2rem;

    img{
        width:100%;
        height:200px;
        border-radius: 15px;
        object-fit:cover;
    }

    @media screen and(min-width: 2000px){

        width:400px;
        margin: 2rem 4rem;

        img{
            height:320px;
        }
    }
}