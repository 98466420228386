
#home{
    position:relative;
    //background: url('../../assets/bgIMG.png');
    background: url('../../assets/bg9.png');
    background-size:cover;
    background-repeat: repeat;
    background-position: top left;

    .app__wrapper{
        padding:0;
        

        .copyright{
            display:none;
        }
    }

    @media screen and(max-width:1400px){
        //background-position: top right;
    }
}

.app__header{
    flex:1;
    width: 100%;
    height: 100%;
    flex-direction: row;
    //padding: 6rem 2rem 0;

    padding: 6rem 1rem 0rem 18rem;

    @media screen and (min-width:2000px){
        padding-top: 8rem;
    }
    @media screen and (max-width:1400px){
        flex-direction: column;
        padding: 6rem 2rem 0;
    }
    @media screen and (max-width:450px){
        padding: 6rem 1rem 2rem;
    }
}

.app__header-info{
    flex:1;
    //flex:0.50;
    display:flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: flex-start;
    height:100%;

    margin-top: 0rem;

    @media screen and(min-width:1400px){
        margin-top: 2.5rem;
    }
    @media screen and(max-width:2000px){
        width:100%;
        margin-right: 0rem;
    }
}

.app__header-badge{
    width:100%;
    display:flex;
    justify-content:flex-end;
    align-items: flex-end;
    flex-direction: column;

    .badge-cmp, .tag-cmp{
        padding: 1rem 1rem;
        margin:0.5rem 1rem;
        border:var(--white-color);
        border-radius:15px;
        flex-direction: row;
        width:auto;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        background:var(--white-color);
    }

    .badge-cmp{
        display:flex;
        justify-content:center;
        align-items: center;
        flex-direction: column;

        div{
            display:flex;
            justify-content:center;
            align-items: center;
            flex-direction: column;
        }
        .badge-cmp-sub{
            display:flex;
            justify-content:center;
            align-items: center;
            flex-direction: row;
        }

        h1{
            font-family: var(--font-script);
        }
        span{
            font-size:2.9rem;
        }
        .app__header-img-small{

            display:none;

            img{
                width:250px;
                //height:200px;
            }
    
            @media screen and(max-width:1400px){
                display: flex;
                justify-content:center;
                align-items: center;
                flex-direction: column;
            }
        }

        @media screen and(max-width:1400px){
            width:300px;
            padding: 1rem 1rem 0;
        }
    }
    .tag-cmp{
        
        flex-direction: column;
        //margin-top:1rem;

        p{
            width:100%;
            font-weight: bold;
            text-transform: uppercase;
            text-align: right;
        }

    }


    @media screen and(max-width:1400px){

        justify-content: center;
        align-items:center;

    }
}

.app__header-circles{
    flex:0.70;
    display:flex;
    //display:none;
    flex-direction: column;
    justify-content: space-evenly;
    //justify-content: space-evenly;
    align-items:flex-start; 
    height:100%;
    margin-left:7%;

    div{
        width:150px;
        height:150px;
        border-radius:  50%;
        background:var(--white-color);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

        img{
            width:60%;
            height:60%;
        }
    }

    div:nth-child(1){
        width:150px;
        height:150px;
    }
    div:nth-child(2){
        margin: 1.9rem;
        width:180px;
        height:180px;
    }
    div:nth-child(3){
        margin-left: 0.6rem;
        width:135px;
        height:135px;
    }

    @media screen and(min-width:2000px){

        div:nth-child(1){
            width:400px;
            height:400px;
        }
        div:nth-child(2){
            margin: 1.75rem;
            width:170px;
            height:170px;
        }
        div:nth-child(3){
            width:200px;
            height:200px;
        }

    }

    @media screen and(max-width:1400px){
        width:100%;
        flex-direction:row;
        flex-wrap:wrap;
        margin-left:0;

        div{
            margin: 1rem;
        }
    }
}

.app__header-img{

    display:flex;
    flex:1;

    height: 100%;
    justify-content: center;
    align-items: flex-end;
    position:relative;
    margin-left: -7rem;

    @media screen and(max-width:1400px){

        display:none;
    }

    /*
    img{
        position:relative;
        //@at-root:100%;
        height:100%;
        //object-fit:contain;
        z-index: 1;
        @media screen and(max-width:900px){

            //width:80%;
        }

    }
    */
    .overlay_circle{
        display:none;
        position:absolute;
        left:0;
        right:0;
        bottom:0;
        z-index: 0;
        width:100%;
        height:90%;
    }

    @media screen and(max-width:1400px){
        margin: -10rem 0 2rem 0;
    }
    @media screen and(max-width:900px){

        margin: 2rem 0 ;
    }
    

}